import "core-js/modules/es.array.push.js";
import "@tonWallet/index";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "layout",
  inject: ["reload"],
  data() {
    const menuList = [{
      "name": "首页",
      "path": "/"
    }, {
      "name": "统计",
      "path": "/stat"
    }, {
      "name": "系统邀请",
      "path": "/ref"
    }, {
      "name": "客户邀请",
      "path": "/uref"
    }, {
      "name": "官方任务",
      "path": "/offitask"
    }, {
      "name": "扩展任务",
      "path": "/task"
    }, {
      "name": "广告",
      "path": "/adv"
    }, {
      "name": "幸运矿机",
      "path": "/minelucky"
    }, {
      "name": "矿机分类",
      "path": "/minecate"
    }, {
      "name": "矿机任务",
      "path": "/minetask"
    }, {
      "name": "幸运抽奖",
      "path": "/luckylottery"
    }, {
      "name": "推送任务",
      "path": "/pushtask"
    }, {
      "name": "提现记录",
      "path": "/withdrawlogs"
    }, {
      "name": "提现发币",
      "path": "/withdrawdelivers"
    }, {
      "name": "转账账户",
      "path": "/tonaccount"
    }, {
      "name": "资产配置",
      "path": "/assetstokens"
    }, {
      "name": "用户资产",
      "path": "/userassetsstat"
    }, {
      "name": "空投任务",
      "path": "/airdrop"
    }, {
      "name": "模拟用户",
      "path": "/mockuser"
    }, {
      "name": "参数配置",
      "path": "/sysconfig"
    }];
    return {
      menuList,
      selectItem: "",
      unsubscribe: null
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", 'setting']),
    ...walletVuex.mapGetters(["isWallet"]),
    route() {
      return this.$route.path;
    }
  },
  watch: {},
  methods: {
    onMenu(item) {
      this.selectItem = item;
      this.$router.push(item.path);
    }
  },
  mounted() {
    this.menuList.map(item => {
      if (item.path == this.$route.path) {
        this.selectItem = item;
      }
    });
  }
};
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "gift",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting'])
  },
  data() {
    return {
      loadConfig,
      isLock: false,
      list: {},
      popTaskShow: false,
      modifyData: {
        "icon": ""
      },
      isLoading: false,
      selectTab: 0,
      total: 0,
      condition: {
        page: 1,
        limit: 15
      }
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let rs = await this.$http.post("/task/list", {
        status: this.selectTab == 0 ? 1 : 0,
        'is_official': 1,
        ...this.condition
      });
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data.items;
        this.total = rs.data.total;
      }
    },
    async onCreate() {
      this.modifyData = {
        "icon": "",
        'status': "1",
        "type": 'telegram',
        'verifiable': "0",
        "reward_token_symbol": "",
        "reward_token_chain": "ton",
        "limit_count": "0",
        "is_official": "1"
      };
      this.popTaskShow = true;
    },
    async onModify(item) {
      this.modifyData = item;
      this.popTaskShow = true;
    },
    cutStr(str) {
      if (str) return str.replace("T", " ").replace(".000Z", "");
    },
    onRefresh() {
      this.getData();
    },
    onPageChange(e) {
      this.condition.page = e;
      this.getData();
    },
    onPageSizeChange(e) {
      this.condition.limit = e;
      this.getData();
    }
  },
  mounted() {
    this.getData();
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onCreate
    }
  }, [_vm._v("创建新任务")]), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)]), _c('div', {
    staticClass: "tabs"
  }, [_c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 0 && 'active',
    on: {
      "click": function ($event) {
        _vm.selectTab = 0;
        _vm.onRefresh();
      }
    }
  }, [_vm._v("启用的")]), _c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 1 && 'active',
    on: {
      "click": function ($event) {
        _vm.selectTab = 1;
        _vm.onRefresh();
      }
    }
  }, [_vm._v("禁用的")])]), _c('div', {
    staticClass: "task-list"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "task-list-item"
    }, [_c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.id) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "s icon"
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "height": "30px"
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "s type"
    }, [_vm._v(" " + _vm._s(item.type) + " ")]), _c('div', {
      staticClass: "s reward"
    }, [_vm._v(" " + _vm._s(item.reward) + " ")]), _c('div', {
      staticClass: "s reward"
    }, [_vm._v(" " + _vm._s(item.reward_stock) + " ")]), _c('div', {
      staticClass: "s reward"
    }, [_vm._v(" " + _vm._s(item.answer) + " ")]), _c('div', {
      staticClass: "s url"
    }, [_vm._v(" " + _vm._s(item.url) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.target) + " ")]), _c('div', {
      staticClass: "s status",
      class: `status_${item.status}`
    }, [_vm._v(" " + _vm._s(item.status == 0 ? '禁用' : '启用') + " ")]), _c('div', {
      staticClass: "s status",
      class: `status_${item.verifiable}`
    }, [_vm._v(" " + _vm._s(item.verifiable == 0 ? '否' : '是') + " ")]), _c('div', {
      staticClass: "s num",
      class: `status_${item.cyclical}`
    }, [_vm._v(" " + _vm._s(item.cyclical == 0 ? '否' : '是') + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.cycle_interval) + " ")]), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.daily_limit_count) + " ")]), _c('div', {
      staticClass: "s sort"
    }, [_vm._v(" " + _vm._s(item.sort) + " ")]), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.completed_count) + " ")]), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.limit_count) + " ")]), _c('div', {
      staticClass: "s status"
    }, [_vm._v(" " + _vm._s(item.click_count) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.remarks) + " ")]), _c('div', {
      staticClass: "s create"
    }, [_vm._v(" " + _vm._s(_vm.cutStr(item.created_at)) + " ")]), _c('div', {
      staticClass: "s opt"
    }, [_c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onModify(item);
        }
      }
    }, [_vm._v("编辑")])])]);
  })], 2), _c('pop-sub-task', {
    attrs: {
      "show": _vm.popTaskShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popTaskShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-list-item"
  }, [_c('div', {
    staticClass: "s"
  }, [_vm._v(" ID ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 标题 ")]), _c('div', {
    staticClass: "s icon"
  }, [_vm._v(" ICON ")]), _c('div', {
    staticClass: "s type"
  }, [_vm._v(" 类型 ")]), _c('div', {
    staticClass: "s reward"
  }, [_vm._v(" 奖励 ")]), _c('div', {
    staticClass: "s reward"
  }, [_vm._v(" 奖励KEY ")]), _c('div', {
    staticClass: "s reward"
  }, [_vm._v(" 答案 ")]), _c('div', {
    staticClass: "s url"
  }, [_vm._v(" 地址 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 目标名 ")]), _c('div', {
    staticClass: "s status"
  }, [_vm._v(" 状态 ")]), _c('div', {
    staticClass: "s status"
  }, [_vm._v(" 强验证 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 周期性任务 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 周期间隔（秒） ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 每日上限 ")]), _c('div', {
    staticClass: "s sort"
  }, [_vm._v(" 排序 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 完成数 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 限制完成数 ")]), _c('div', {
    staticClass: "s sort"
  }, [_vm._v(" 点击数 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 备注 ")]), _c('div', {
    staticClass: "s create"
  }, [_vm._v(" 添加时间 ")]), _c('div', {
    staticClass: "s opt"
  })]);
}];
export { render, staticRenderFns };
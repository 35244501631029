import "core-js/modules/es.array.push.js";
import axios from "axios";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
import cookie from "js-cookie";
import vailcode from "@utils/errcode";
export default {
  name: "popRecharge",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  data() {
    return {
      chainList: [],
      formData: {},
      isSubmit: false
    };
  },
  watch: {
    "$parent.popRechargeShow": {
      async handler(newVal, oldVal) {
        if (newVal) {
          this.formData = Object.assign({}, this.$parent.modifyData);
          let chainList = [];
          let rs = await this.$http.post("/assets/chains", {});
          if (rs.code == 0) {
            chainList = rs.data;
          }
          rs = await this.$http.post("/assets/tokens", {
            chain_id: this.chain_id
          });
          if (rs.code == 0) {
            rs.data.map(item => {
              chainList.map(sitem => {
                if (!sitem.tokens) {
                  sitem.tokens = [];
                }
                if (sitem.id == item.chain) {
                  sitem.tokens.push(item);
                }
                return sitem;
              });
            });
          }
          this.chainList = chainList;
          if (!this.formData.chain && !this.formData.symbol) this.onChange((this.chainList[0]['tokens'][0]['chain'] || '') + "|" + (this.chainList[0]['tokens'][0]['symbol'] || ''));
        } else {
          this.isSubmit = false;
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async onConfirm() {
      if (this.isSubmit) return false;
      this.isSubmit = true;
      let rs = await this.$http.post("/user/recharge", this.formData);
      this.isSubmit = false;
      vailcode(rs, () => {
        this.$parent.getData();
        this.$parent.popRechargeShow = false;
      });
    },
    onChange(e) {
      this.formData.chain = e.split("|")[0] || '';
      this.formData.symbol = e.split("|")[1] || '';
    }
  }
};
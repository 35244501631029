import "core-js/modules/es.array.push.js";
import axios from "axios";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
import cookie from "js-cookie";
import vailcode from "@utils/errcode";
export default {
  name: "popTask",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"])
  },
  data() {
    return {
      modifyData: {},
      isUploading: false,
      isSubmit: false,
      disp_combo0: "",
      disp_combo1: "",
      disp_combo2: ""
    };
  },
  watch: {
    "$parent.popTaskShow": {
      async handler(newVal, oldVal) {
        if (newVal) {
          this.modifyData = Object.assign({}, this.$parent.modifyData);
          if (this.modifyData?.id > 0) {
            try {
              this.modifyData.disp_combo = JSON.parse(this.modifyData.disp_combo);
            } catch (e) {
              this.modifyData.disp_combo = [];
            }
          } else {
            this.modifyData.disp_combo = [];
          }
          this.modifyData.disp_combo.map((item, index) => {
            this['disp_combo' + index] = item;
            this.$set(this, 'disp_combo' + index, item);
            return item;
          });
        } else {
          this.isSubmit = false;
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onFileChange(e) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      if (!e.target.files[0].type.startsWith('image/')) {
        this.$refs.fileInput.value = '';
        this.$toasted.error('请选择图片');
        return false;
      }
      if (this.isUploading) return;
      this.isUploading = true;
      let token = cookie.get('token') || '';
      formData.append('_token', token);
      // 使用axios上传文件
      axios.post(`${loadConfig.http_host}/upload?_token=${token}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        let rs = response.data;
        if (rs.code == 0) {
          this.modifyData.icon = rs.data.url;
          this.$set(this.modifyData, "icon", rs.data.url);
        } else {
          this.$toasted.error(rs.message);
        }
        this.isUploading = false;
        this.$refs.fileInput.value = '';
      }).catch(error => {
        console.error(error);
        this.isUploading = false;
        this.$refs.fileInput.value = '';
      });
    },
    async onConfirm() {
      if (this.isSubmit) return false;
      this.isSubmit = true;
      this.modifyData.disp_combo = [];
      if (this.disp_combo0) this.modifyData.disp_combo.push(this.disp_combo0);
      if (this.disp_combo1) this.modifyData.disp_combo.push(this.disp_combo1);
      if (this.disp_combo2) this.modifyData.disp_combo.push(this.disp_combo2);
      let rs = await this.$http.post("/luckylottery/modifyitems", this.modifyData);
      this.isSubmit = false;
      vailcode(rs, () => {
        this.$parent.getData();
        this.$parent.popTaskShow = false;
      });
    }
  }
};
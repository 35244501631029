import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
import popJoinLuckyBox from '../components/popJoinLuckyBox.vue';
export default {
  components: {
    popJoinLuckyBox
  },
  name: "MockUser",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting'])
  },
  data() {
    return {
      loadConfig,
      isLock: false,
      list: {},
      popMockUserShow: false,
      popRechargeShow: false,
      popJoinLuckyBoxShow: false,
      modifyData: {},
      isLoading: false
    };
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let rs = await this.$http.post("/mockuser/list", {});
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data;
      }
    },
    async onCreate() {
      // let rs = await this.$http.post("/referral/modify",{});
      // if(rs.code == 0){
      //   this.list = rs.data
      // }
      this.modifyData = {
        username: "",
        firstname: "",
        lastname: ""
      };
      this.popMockUserShow = true;
    },
    onModify(item) {
      this.modifyData = item;
      this.popMockUserShow = true;
    },
    onRecharge(item) {
      this.modifyData = item;
      this.modifyData.chain = 'ton';
      this.modifyData.symbol = 'TON';
      this.popRechargeShow = true;
    },
    onJoinLuckyBox(item) {
      this.modifyData = item;
      this.modifyData.amount = 1;
      this.popJoinLuckyBoxShow = true;
    },
    onRefresh() {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  }
};
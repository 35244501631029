var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ref-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onCreate
    }
  }, [_vm._v("创建新用户")]), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)]), _c('div', {
    staticClass: "ref-list"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "ref-list-item"
    }, [_c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.id) + " ")]), _c('div', {
      staticClass: "s name"
    }, [_vm._v(" " + _vm._s(item.firstname) + " ")]), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.balance || 0) + " ")]), _c('div', {
      staticClass: "s opt"
    }, [_c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onModify(item);
        }
      }
    }, [_vm._v("编辑")]), _c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onRecharge(item);
        }
      }
    }, [_vm._v("充值")]), _c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onJoinLuckyBox(item);
        }
      }
    }, [_vm._v("幸运盒子")])])]);
  })], 2), _c('pop-mock-user', {
    attrs: {
      "show": _vm.popMockUserShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popMockUserShow = $event;
      }
    }
  }), _c('pop-recharge', {
    attrs: {
      "show": _vm.popRechargeShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRechargeShow = $event;
      }
    }
  }), _c('pop-join-lucky-box', {
    attrs: {
      "show": _vm.popJoinLuckyBoxShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popJoinLuckyBoxShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ref-list-item"
  }, [_c('div', {
    staticClass: "s"
  }, [_vm._v(" ID ")]), _c('div', {
    staticClass: "s name"
  }, [_vm._v(" 名称 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" TON ")]), _c('div', {
    staticClass: "s opt"
  }, [_vm._v(" 操作 ")])]);
}];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onCreate
    }
  }, [_vm._v("创建新奖项")]), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)]), _c('div', {
    staticClass: "tabs"
  }, [_c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 0 && 'active',
    on: {
      "click": function ($event) {
        _vm.selectTab = 0;
        _vm.onRefresh();
      }
    }
  }, [_vm._v("启用的")]), _c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 1 && 'active',
    on: {
      "click": function ($event) {
        _vm.selectTab = 1;
        _vm.onRefresh();
      }
    }
  }, [_vm._v("禁用的")])]), _c('div', {
    staticClass: "task-list"
  }, [_vm._m(0), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "task-list-item"
    }, [_c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.id) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('div', {
      staticClass: "s icon"
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "height": "30px"
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "s type"
    }, [_vm._v(" " + _vm._s(item.currency) + " ")]), _c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.amount) + " ")]), _c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.winning_rate) + " ")]), _c('div', {
      staticClass: "s"
    }, [_vm._v(" " + _vm._s(item.daily_limit) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.winning_count) + " ")]), _c('div', {
      staticClass: "s url"
    }, [_vm._v(" " + _vm._s(item.winning_amount) + " ")]), _c('div', {
      staticClass: "s create"
    }, [_vm._v(" " + _vm._s(item.daily_win_date) + " ")]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" " + _vm._s(item.daily_win_count) + " ")]), _c('div', {
      staticClass: "s url"
    }, [_vm._v(" " + _vm._s(item.disp_combo) + " ")]), _c('div', {
      staticClass: "s sort"
    }, [_vm._v(" " + _vm._s(item.sort) + " ")]), _c('div', {
      staticClass: "s status",
      class: `status_${item.status}`
    }, [_vm._v(" " + _vm._s(item.status == 0 ? '禁用' : '启用') + " ")]), _c('div', {
      staticClass: "s create"
    }, [_vm._v(" " + _vm._s(_vm.cutStr(item.created_at)) + " ")]), _c('div', {
      staticClass: "s opt"
    }, [_c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onLogs(item.id);
        }
      }
    }, [_vm._v("奖励记录")]), _c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onModify(item);
        }
      }
    }, [_vm._v("编辑")])])]);
  })], 2), _c('pop-luckylottery-items', {
    attrs: {
      "show": _vm.popTaskShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popTaskShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task-list-item"
  }, [_c('div', {
    staticClass: "s"
  }, [_vm._v(" ID ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 奖项标题 ")]), _c('div', {
    staticClass: "s icon"
  }, [_vm._v(" ICON ")]), _c('div', {
    staticClass: "s type"
  }, [_vm._v(" 奖励货币 ")]), _c('div', {
    staticClass: "s"
  }, [_vm._v(" 奖励货币金额 ")]), _c('div', {
    staticClass: "s"
  }, [_vm._v(" 中奖概率 ")]), _c('div', {
    staticClass: "s"
  }, [_vm._v(" 每日中奖上限 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 中奖总次数 ")]), _c('div', {
    staticClass: "s url"
  }, [_vm._v(" 中奖总金额 ")]), _c('div', {
    staticClass: "s create"
  }, [_vm._v(" 最新中奖日期 ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 最新日中奖数量 ")]), _c('div', {
    staticClass: "s url"
  }, [_vm._v(" 显示组合 ")]), _c('div', {
    staticClass: "s sort"
  }, [_vm._v(" 排序 ")]), _c('div', {
    staticClass: "s status"
  }, [_vm._v(" 状态 ")]), _c('div', {
    staticClass: "s create"
  }, [_vm._v(" 创建时间 ")]), _c('div', {
    staticClass: "s opt"
  })]);
}];
export { render, staticRenderFns };